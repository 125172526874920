import design_icon from "../assets/images/design-icon.svg";
import brand_icon from "../assets/images/brand-icon.svg";
import digital_icon from "../assets/images/digital-icon.svg";
import marketing_icon from "../assets/images/marktng-icon.svg";
import public_relation_icon from "../assets/images/public-relatn-icon.svg";
import multimedia_icon from "../assets/images/multimedia.svg";
import media_icon from "../assets/images/media-icon.svg";
import event_management from "../assets/images/event icon.svg";

export const serviceData = [
  {
    icon: design_icon,
    className: "rotate-in-up-left",
    title: "design",
    description: "design_desc",
    pageDescritpion: "design_page_desc",
    href: "/graphic-design",
  },
  {
    icon: brand_icon,
    className: "swing",
    title: "branding",
    description: "branding_desc",
    pageDescritpion: "branding_page_desc",
    href: "/branding",
  },
  {
    icon: digital_icon,
    className: "wobble",
    title: "digital",
    description: "digital_desc",
    pageDescritpion: "digital_page_desc",
    href: "/digital",
  },
  {
    icon: marketing_icon,
    className: "rotate-in-up-left2",
    title: "marketing_and_advertising",
    description: "marketing_and_advertising_desc",
    pageDescritpion: "marketing_and_advertising_page_desc",
    href: "/marketing-advertising",
  },
  {
    icon: public_relation_icon,
    className: "shake2",
    title: "public_relations",
    description: "public_relations_desc",
    pageDescritpion: "public_relations_page_desc",
    href: "/public-relations",
  },
  {
    icon: multimedia_icon,
    className: "gelatine",
    title: "multimedia",
    description: "multimedia_desc",
    pageDescritpion: "multimedia_page_desc",
    href: "/multimedia",
  },
  {
    icon: media_icon,
    className: "shake",
    title: "social_media",
    description: "social_media_desc",
    pageDescritpion: "social_media_page_desc",
    href: "/media",
  },
  {
    icon: event_management,
    className: "shake h-[160px]",
    title: "event_management",
    description: "event_management_desc",
    pageDescritpion: "event_management_page_desc",
    href: "/event-management",
  },
];
