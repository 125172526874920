import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import visit_icon from "../assets/images/visit-icon.svg";
import contact_icon from "../assets/images/contact.svg";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../context/LanguageContext";

const ContactUs = () => {
  const { t } = useTranslation();
  const { lang } = useLanguage();
  const navigate = useNavigate();
  const address =
    lang === "ar"
      ? `أسباير زون \nالطابق الثاني مكتب S05 من بناية Sport Accelerator\nالدوحة ، قطر`
      : `Aspire Zone\nSport Accelerator Building, 2nd Floor - S05\nDoha, Qatar`;
  const [addressTitle, setAddress] = useState(address);
  const defaultProps = {
    center: {
      lat: 25.263914809574633,
      lng: 51.440856499999995,
    },
    zoom: 11,
  };

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: 25.263914809574633, lng: 51.440856499999995 },
      map,
      title: addressTitle,
    });
    return marker;
  };

  useEffect(() => {
    let str =
      lang === "ar"
        ? `أسباير زون \nالطابق الثاني مكتب S05 من بناية Sport Accelerator\nالدوحة ، قطر`
        : `Aspire Zone\nSport Accelerator Building, 2nd Floor - S05\nDoha, Qatar`;
    setAddress(str);
  }, [lang]);
  return (
    <div id="Office">
      <div className="row g-0 pt-4">
        <div className="col-lg-5">
          <section className="contact-us-sec bg-primary-color ">
            <div>
              <h3>
                {/* <span className="d-inline-block mb-2" >{t("hey_there")}</span>
                <br /> */}
                <span
                  className="d-inline-block" /* data-aos="fade-left" data-aos-duration="1000" */
                >
                  {t("interested_in_discovering_more")}
                </span>{" "}
                <br />
              </h3>
              <h6
                className="d-block mb-4 pb-2" /* data-aos="fade-up" data-aos-duration="1000" */
              >
                {t("connect_with_us")}
              </h6>
              <button
                type="button"
                className="d-block btn primary-btn contact-us-btn" /* data-aos="zoom-out-down" data-aos-duration="1000" */
                onClick={() => {
                  navigate(`/contact`);
                }}
              >
                {t("contact_us")}
              </button>
            </div>
          </section>
        </div>
        <div className="col-lg-7">
          <div className="map-holder  position-relative">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2285252.210185158!2d50.78193059664574!3d25.29911989705365!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45dbca75014f1b%3A0x414f83cd31a8636d!2sLoom!5e0!3m2!1sen!2sqa!4v1722200037143!5m2!1sen!2sqa"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Google Maps showing location of Aspire Zone,Sport Accelerator Building,2nd Floor - S05,Doha, Qatar"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>

            <div className="address-overlay">
              <div className="row gx-0 justify-content-center mt-2">
                <div className="col-6 col-sm-3 pt-1">
                  <img
                    src={visit_icon}
                    className="img-fluid pb-2"
                    loading="lazy"
                    alt=""
                  />
                  <h5>{t("visit_us")}</h5>
                  <p>
                    {t("aspire_zone")}
                    <br />
                    {t("sport_accelerator_building")}
                    <br />
                    {t("_2nd_floor_S05")}
                    <br />
                    {t("doha_qatar")}
                  </p>
                </div>
                <div className="col-6 col-sm-3">
                  <img
                    src={contact_icon}
                    className="img-fluid pb-2"
                    loading="lazy"
                    alt=""
                  />
                  <h5>{t("contact_us_:")}</h5>
                  <p className="mb-0">
                    <a href="#">info@loom.qa</a>
                  </p>
                  <p>
                    <a href="tel:+97440083118">+974 4008 3118</a>
                  </p>
                  <p>
                    <a href="tel:+97430303355">+974 3030 3355</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
