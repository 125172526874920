import React from "react";
import robot from "../assets/images/robot.png";
import rocket from "../assets/images/rocket.png";
import { useTranslation } from "react-i18next";

const AboutBrand = () => {
  const { t } = useTranslation();

  return (
    <div id="Brand">
      <section className="sec-padding">
        <div className="container-lg">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5 col-xxl-4 order-1 order-lg-0">
              <figure className="brandInfo-img">
                <img
                  src={robot}
                  className="img-fluid floating"
                  alt=""
                  loading="lazy"
                />
                <img
                  src={rocket}
                  className="img-fluid rocketImg"
                  alt=""
                  loading="lazy"
                />
              </figure>
            </div>
            <div className="col-lg-6 col-xxl-5 order-0 order-lg-1 ">
              <div className="sec-content">
                <h2 className="mb-3">{t("who_we_are")}</h2>

                <h4 className="mb-3">{t("about_desc_1")}</h4>
                <p>{t("about_desc_2")}</p>
                <p>{t("about_desc_3")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutBrand;
