import React from "react";
import loader_icon from "../assets/images/favicon.png";

const Loader = () => {
  return (
    <>
      <div className="loader-holder">
        <img
          src={loader_icon}
          className="img-fluid grow"
          alt=""
          loading="lazy"
        />
      </div>
    </>
  );
};

export default Loader;
