import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { workData } from "../data/workData";
import { Modal, SliderItems } from ".";
import { useTranslation } from "react-i18next";

const OurService = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const openModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false);
  const onClick = (video) => {
    setVideoUrl(video);
    openModal();
  };

  const settings = {
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          autoplaySpeed: 3000,
          infinite: true,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          infinite: true,
          arrows: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onAfterOpen={() => {}}
        closeModal={closeModal}
        children={
          <video preload="true" className="popup-video" autoPlay controls>
            <source src={videoUrl} type="video/mp4" />
          </video>
        }
      />
      <div
        className="container-lg mb-3 pb-3 pt-3"
        id="OurWork"
        style={{ marginTop: "1rem" }}
      >
        <div>
          <div>
            <h2 className="mb-3">{t("our_work")}</h2>
            <h3 className="mb-3">{t("our_work_desc")}</h3>
          </div>
        </div>
      </div>
      <section className="slider-outer sec-padding case-slider mb-2 mb-md-5">
        <div className="container-lg">
          <div className="row">
            <Slider {...settings} className="work-slider">
              {workData?.map((item) => (
                <SliderItems key={item.id} onClick={onClick} {...item} />
              ))}
            </Slider>
          </div>
        </div>
      </section>
      <div
        className="container-lg mb-3 pb-3 pt-3"
        id="OurWork"
        style={{ marginTop: "4rem" }}
      >
        <div>
          <div>
            <h2 className="mb-3">Gallery</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurService;
