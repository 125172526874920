import lapelote from "../assets/images/LaPelote.png";
import sbakha from "../assets/images/Sbakha.png";
import qbf from "../assets/images/QatarBasketballFederation.png";
import qvaVolleyball from "../assets/images/qva_volleyball.png";
import QatarCharity from "../assets/images/QatarCharity.png";
import tawaq from "../assets/images/Twaq.png";
import msdf from "../assets/images/MinistryofSocialDevelopmentandFamily.png";
import sportAccelerator from "../assets/images/SportAccelerator.png";
import mjharr from "../assets/images/Mjhar.png";
import NjahQatari from "../assets/images/NjahQatari.png";
import carbon_car_img from "../assets/images/carbon_car_img.png";
import qe1_imge from "../assets/images/QE1.png";
import qe2_imge from "../assets/images/QE2.png";
import qe3_imge from "../assets/images/QE3.png";
import qe4_imge from "../assets/images/QE4.png";
import Qatar_Debate_img from "../assets/images/Qatar_Debate.png";
import ekbes_day_img from "../assets/images/ekbes_day.png";
import recap_with_2023_with_loom from "../assets/images/recap-with-2023-loom.png";
import chef_hamad_promo from "../assets/images/chef-hamad-promo.png";
import comanche_restaurant from "../assets/images/comanche-restaurant.png";
import ekbes_2024 from "../assets/images/ekbes-2023.png";
import ekbes_promo_2023 from "../assets/images/ekbes-promo.png";
import hamad_book_edition from "../assets/images/hamad-book-edition.png";
import fekra_program from "../assets/images/fekra-program.png";
import vicuna from "../assets/images/vicuna.png";
import vicuna2 from "../assets/images/vicuna-2.png";
import charlottine from "../assets/images/charlottine.png";

export const workData = [
  {
    title: "la_pelota",
    image: lapelote,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 1,
    video: "https://loom.qa/video/la_pelota.mp4",
  },
  {
    title: "sbakha",
    image: sbakha,
    campaign: "Platform",
    btn_title: "watch_video",
    id: 2,
    video: "https://loom.qa/video/sbakha_video.mp4",
  },
  {
    title: "njah_qatari",
    image: NjahQatari,
    campaign: "Platform",
    btn_title: "watch_video",
    id: 3,
    video: "https://loom.qa/video/Njah_Qatari.MP4",
  },
  {
    title: "qbf",
    image: qbf,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 4,
    video: "https://loom.qa/video/QBF.mp4",
  },
  {
    title: "qva_amir_cup",
    image: qvaVolleyball,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 5,
    video: "https://loom.qa/video/final_qva_volleyball.mp4",
  },
  {
    title: "qatar_charity",
    image: QatarCharity,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 6,
    video: "https://loom.qa/video/football.mp4",
  },
  {
    title: "tawaq",
    image: tawaq,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 7,
    video: "https://loom.qa/video/Tawaq.mp4",
  },
  {
    title: "msdf",
    image: msdf,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 8,
    video: "https://loom.qa/video/video_2.mp4",
  },
  // {
  //   title: "icy_melon",
  //   image: icyMelon,
  //   campaign: "Campaign",
  //   btn_title: "watch_video",
  //   id: 9,
  //   video: "https://loom.qa/video/Icy_melon.mp4",
  // },
  {
    title: "sport_accelerator",
    image: sportAccelerator,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 10,
    video: "https://loom.qa/video/sport_accelerator.mp4",
  },
  {
    title: "mjharr",
    image: mjharr,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 11,
    video: "https://loom.qa/video/Mjhar.mov",
  },
  {
    title: "Carbono",
    image: carbon_car_img,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 12,
    video: "https://loom.qa/video/carbono_car_loom.mp4",
  },
  {
    title: "qatar_questrian_1",
    image: qe1_imge,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 13,
    video: "https://loom.qa/video/QE1.mp4",
  },
  {
    title: "qatar_questrian_2",
    image: qe2_imge,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 14,
    video: "https://loom.qa/video/QE2.mp4",
  },
  {
    title: "qatar_questrian_3",
    image: qe3_imge,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 15,
    video: "https://loom.qa/video/QE3.mp4",
  },
  {
    title: "qatar_questrian_4",
    image: qe4_imge,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 16,
    video: "https://loom.qa/video/QE4.mp4",
  },
  {
    title: "ekbes_2023",
    image: ekbes_day_img,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 17,
    video: "https://loom.qa/video/ekbes_day.MP4",
  },
  {
    title: "qatar_debate",
    image: Qatar_Debate_img,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 18,
    video: "https://loom.qa/video/Qatar_Debate.mp4",
  },
  {
    title: "chef_hamad_promo",
    image: chef_hamad_promo,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 19,
    video: "https://loom.qa/video/chef-hamad-promo.mp4",
  },
  {
    title: "chef_hamad_promo_2",
    image: chef_hamad_promo,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 20,
    video: "https://loom.qa/video/chef-hamad-promo-li.mp4",
  },
  {
    title: "comanche_restaurant",
    image: comanche_restaurant,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 21,
    video: "https://loom.qa/video/comanche-resturant.mp4",
  },
  {
    title: "ekbes_promo_2023",
    image: ekbes_promo_2023,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 22,
    video: "https://loom.qa/video/ekbes-promo-2023.mp4",
  },
  {
    title: "ekbes_2024",
    image: ekbes_2024,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 23,
    video: "https://loom.qa/video/ekbes-2024-Ii.mp4",
  },
  {
    title: "fekra_program",
    image: fekra_program,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 24,
    video: "https://loom.qa/video/fekra-program.mp4",
  },
  {
    title: "hamad_book_edition_promo",
    image: hamad_book_edition,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 25,
    video: "https://loom.qa/video/hamad-book-edition-promo.mp4",
  },
  {
    title: "recap_2023_with_loom",
    image: recap_with_2023_with_loom,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 26,
    video: "https://loom.qa/video/rcap-2023-with-loom.mp4",
  },
  {
    title: "vicuna",
    image: vicuna,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 27,
    video: "https://loom.qa/video/vicuna.mp4",
  },
  {
    title: "vicuna_2",
    image: vicuna2,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 28,
    video: "https://loom.qa/video/vicuna-2.mp4",
  },
  {
    title: "charlottine",
    image: charlottine,
    campaign: "Campaign",
    btn_title: "watch_video",
    id: 28,
    video: "https://loom.qa/video/charlottine-video.mp4",
  },
];
