import aljf_1 from "../assets/images/gallery/al-jazeera-forum/aljf-1.jpg";
import aljf_2 from "../assets/images/gallery/al-jazeera-forum/aljf-2.jpg";
import aljf_3 from "../assets/images/gallery/al-jazeera-forum/aljf-3.jpg";
import aljf_4 from "../assets/images/gallery/al-jazeera-forum/aljf-4.jpg";
import aljf_5 from "../assets/images/gallery/al-jazeera-forum/aljf-5.jpg";

import als2023_1 from "../assets/images/gallery/al-shaqab-2023/als2023-1.jpg";
import als2023_2 from "../assets/images/gallery/al-shaqab-2023/als2023-2.jpg";
import als2023_3 from "../assets/images/gallery/al-shaqab-2023/als2023-3.jpg";
import als2023_4 from "../assets/images/gallery/al-shaqab-2023/als2023-4.jpg";
import als2023_5 from "../assets/images/gallery/al-shaqab-2023/als2023-5.jpg";
import als2023_6 from "../assets/images/gallery/al-shaqab-2023/als2023-6.jpg";
import als2023_7 from "../assets/images/gallery/al-shaqab-2023/als2023-7.jpg";
import als2023_8 from "../assets/images/gallery/al-shaqab-2023/als2023-8.jpg";
import als2023_9 from "../assets/images/gallery/al-shaqab-2023/als2023-9.jpg";
import als2023_10 from "../assets/images/gallery/al-shaqab-2023/als2023-10.jpg";
import als2023_11 from "../assets/images/gallery/al-shaqab-2023/als2023-11.jpg";
import als2023_12 from "../assets/images/gallery/al-shaqab-2023/als2023-12.jpg";
import als2023_13 from "../assets/images/gallery/al-shaqab-2023/als2023-13.jpg";
import als2023_14 from "../assets/images/gallery/al-shaqab-2023/als2023-14.jpg";
import als2023_15 from "../assets/images/gallery/al-shaqab-2023/als2023-15.jpg";
import als2023_16 from "../assets/images/gallery/al-shaqab-2023/als2023-16.jpg";
import als2023_17 from "../assets/images/gallery/al-shaqab-2023/als2023-17.jpg";
import als2023_18 from "../assets/images/gallery/al-shaqab-2023/als2023-18.jpg";
import als2023_19 from "../assets/images/gallery/al-shaqab-2023/als2023-19.jpg";

import arsf_1 from "../assets/images/gallery/arab-sporttech-forum/arsf-1.jpg";
import arsf_2 from "../assets/images/gallery/arab-sporttech-forum/arsf-2.jpg";
import arsf_3 from "../assets/images/gallery/arab-sporttech-forum/arsf-3.jpg";
import arsf_4 from "../assets/images/gallery/arab-sporttech-forum/arsf-4.jpg";
import arsf_5 from "../assets/images/gallery/arab-sporttech-forum/arsf-5.jpg";
import arsf_6 from "../assets/images/gallery/arab-sporttech-forum/arsf-6.jpg";
import arsf_7 from "../assets/images/gallery/arab-sporttech-forum/arsf-7.jpg";
import arsf_8 from "../assets/images/gallery/arab-sporttech-forum/arsf-8.jpg";

import ekb_1 from "../assets/images/gallery/ekbes/ekb-1.jpg";
import ekb_2 from "../assets/images/gallery/ekbes/ekb-2.jpg";
import ekb_3 from "../assets/images/gallery/ekbes/ekb-3.jpg";
import ekb_4 from "../assets/images/gallery/ekbes/ekb-4.jpg";
import ekb_5 from "../assets/images/gallery/ekbes/ekb-5.jpg";
import ekb_6 from "../assets/images/gallery/ekbes/ekb-6.jpg";
import ekb_7 from "../assets/images/gallery/ekbes/ekb-7.jpg";
import ekb_8 from "../assets/images/gallery/ekbes/ekb-8.jpg";
import ekb_9 from "../assets/images/gallery/ekbes/ekb-9.jpg";
import ekb_10 from "../assets/images/gallery/ekbes/ekb-10.jpg";
import ekb_11 from "../assets/images/gallery/ekbes/ekb-11.jpg";
import ekb_12 from "../assets/images/gallery/ekbes/ekb-12.jpg";
import ekb_13 from "../assets/images/gallery/ekbes/ekb-13.jpg";
import ekb_14 from "../assets/images/gallery/ekbes/ekb-14.jpg";
import ekb_15 from "../assets/images/gallery/ekbes/ekb-15.jpg";
import ekb_16 from "../assets/images/gallery/ekbes/ekb-16.jpg";
import ekb_17 from "../assets/images/gallery/ekbes/ekb-17.jpg";
import ekb_18 from "../assets/images/gallery/ekbes/ekb-18.jpg";
import ekb_19 from "../assets/images/gallery/ekbes/ekb-19.jpg";
import ekb_20 from "../assets/images/gallery/ekbes/ekb-20.jpg";
import ekb_21 from "../assets/images/gallery/ekbes/ekb-21.jpg";
import ekb_22 from "../assets/images/gallery/ekbes/ekb-22.jpg";
import ekb_23 from "../assets/images/gallery/ekbes/ekb-23.jpg";
import ekb_24 from "../assets/images/gallery/ekbes/ekb-24.jpg";
import ekb_25 from "../assets/images/gallery/ekbes/ekb-25.jpg";
import ekb_26 from "../assets/images/gallery/ekbes/ekb-26.jpg";
import ekb_27 from "../assets/images/gallery/ekbes/ekb-27.jpg";
import ekb_28 from "../assets/images/gallery/ekbes/ekb-28.jpg";
import ekb_29 from "../assets/images/gallery/ekbes/ekb-29.jpg";
import ekb_30 from "../assets/images/gallery/ekbes/ekb-30.jpg";
import ekb_31 from "../assets/images/gallery/ekbes/ekb-31.jpg";
import ekb_32 from "../assets/images/gallery/ekbes/ekb-32.jpg";
import ekb_33 from "../assets/images/gallery/ekbes/ekb-33.jpg";
import ekb_34 from "../assets/images/gallery/ekbes/ekb-34.jpg";
import ekb_35 from "../assets/images/gallery/ekbes/ekb-35.jpg";
import ekb_36 from "../assets/images/gallery/ekbes/ekb-36.jpg";
import ekb_37 from "../assets/images/gallery/ekbes/ekb-37.jpg";
import ekb_38 from "../assets/images/gallery/ekbes/ekb-38.jpg";
import ekb_39 from "../assets/images/gallery/ekbes/ekb-39.jpg";
import ekb_40 from "../assets/images/gallery/ekbes/ekb-40.jpg";
import ekb_41 from "../assets/images/gallery/ekbes/ekb-41.jpg";
import ekb_42 from "../assets/images/gallery/ekbes/ekb-42.jpg";
import ekb_43 from "../assets/images/gallery/ekbes/ekb-43.jpg";
import ekb_44 from "../assets/images/gallery/ekbes/ekb-44.jpg";
import ekb_45 from "../assets/images/gallery/ekbes/ekb-45.jpg";
import ekb_46 from "../assets/images/gallery/ekbes/ekb-46.jpg";
import ekb_47 from "../assets/images/gallery/ekbes/ekb-47.jpg";

import gce_1 from "../assets/images/gallery/google-cloud-event/gce-1.jpg";
import gce_2 from "../assets/images/gallery/google-cloud-event/gce-2.jpg";
import gce_3 from "../assets/images/gallery/google-cloud-event/gce-3.jpg";
import gce_4 from "../assets/images/gallery/google-cloud-event/gce-4.jpg";
import gce_5 from "../assets/images/gallery/google-cloud-event/gce-5.jpg";

import hbl_1 from "../assets/images/gallery/hamad-book-launch/hbl-1.jpg";
import hbl_2 from "../assets/images/gallery/hamad-book-launch/hbl-2.jpg";
import hbl_3 from "../assets/images/gallery/hamad-book-launch/hbl-3.jpg";
import hbl_4 from "../assets/images/gallery/hamad-book-launch/hbl-4.jpg";
import hbl_5 from "../assets/images/gallery/hamad-book-launch/hbl-5.jpg";
import hbl_6 from "../assets/images/gallery/hamad-book-launch/hbl-6.jpg";
import hbl_7 from "../assets/images/gallery/hamad-book-launch/hbl-7.jpg";
import hbl_8 from "../assets/images/gallery/hamad-book-launch/hbl-8.jpg";
import hbl_9 from "../assets/images/gallery/hamad-book-launch/hbl-9.jpg";
import hbl_10 from "../assets/images/gallery/hamad-book-launch/hbl-10.jpg";
import hbl_11 from "../assets/images/gallery/hamad-book-launch/hbl-11.jpg";
import hbl_12 from "../assets/images/gallery/hamad-book-launch/hbl-12.jpg";

import lap_1 from "../assets/images/gallery/lap/lap-1.jpg";
import lap_2 from "../assets/images/gallery/lap/lap-2.jpg";
import lap_3 from "../assets/images/gallery/lap/lap-3.jpg";
import lap_4 from "../assets/images/gallery/lap/lap-4.jpg";
import lap_5 from "../assets/images/gallery/lap/lap-5.jpg";
import lap_6 from "../assets/images/gallery/lap/lap-6.jpg";
import lap_7 from "../assets/images/gallery/lap/lap-7.jpg";
import lap_8 from "../assets/images/gallery/lap/lap-8.jpg";
import lap_9 from "../assets/images/gallery/lap/lap-9.jpg";
import lap_10 from "../assets/images/gallery/lap/lap-10.jpg";
import lap_11 from "../assets/images/gallery/lap/lap-11.jpg";
import lap_12 from "../assets/images/gallery/lap/lap-12.jpg";
import lap_13 from "../assets/images/gallery/lap/lap-13.jpg";
import lap_14 from "../assets/images/gallery/lap/lap-14.jpg";
import lap_15 from "../assets/images/gallery/lap/lap-15.jpg";
import lap_16 from "../assets/images/gallery/lap/lap-16.jpg";

import nq_1 from "../assets/images/gallery/najah-qatari/nq-1.jpg";
import nq_2 from "../assets/images/gallery/najah-qatari/nq-2.jpg";
import nq_3 from "../assets/images/gallery/najah-qatari/nq-3.jpg";
import nq_4 from "../assets/images/gallery/najah-qatari/nq-4.jpg";
import nq_5 from "../assets/images/gallery/najah-qatari/nq-5.jpg";
import nq_6 from "../assets/images/gallery/najah-qatari/nq-6.jpg";
import nq_7 from "../assets/images/gallery/najah-qatari/nq-7.jpg";
import nq_8 from "../assets/images/gallery/najah-qatari/nq-8.jpg";
import nq_9 from "../assets/images/gallery/najah-qatari/nq-9.jpg";
import nq_10 from "../assets/images/gallery/najah-qatari/nq-10.jpg";
import nq_11 from "../assets/images/gallery/najah-qatari/nq-11.jpg";
import nq_12 from "../assets/images/gallery/najah-qatari/nq-12.jpg";
import nq_13 from "../assets/images/gallery/najah-qatari/nq-13.jpg";
import nq_14 from "../assets/images/gallery/najah-qatari/nq-14.jpg";
import nq_15 from "../assets/images/gallery/najah-qatari/nq-15.jpg";
import nq_16 from "../assets/images/gallery/najah-qatari/nq-16.jpg";
import nq_17 from "../assets/images/gallery/najah-qatari/nq-17.jpg";
import nq_18 from "../assets/images/gallery/najah-qatari/nq-18.jpg";
import nq_19 from "../assets/images/gallery/najah-qatari/nq-19.jpg";
import nq_20 from "../assets/images/gallery/najah-qatari/nq-20.jpg";
import nq_21 from "../assets/images/gallery/najah-qatari/nq-21.jpg";
import nq_22 from "../assets/images/gallery/najah-qatari/nq-22.jpg";
import nq_23 from "../assets/images/gallery/najah-qatari/nq-23.jpg";
import nq_24 from "../assets/images/gallery/najah-qatari/nq-24.jpg";
import nq_25 from "../assets/images/gallery/najah-qatari/nq-25.jpg";
import nq_26 from "../assets/images/gallery/najah-qatari/nq-26.jpg";
import nq_27 from "../assets/images/gallery/najah-qatari/nq-27.jpg";
import nq_28 from "../assets/images/gallery/najah-qatari/nq-28.jpg";
import nq_29 from "../assets/images/gallery/najah-qatari/nq-29.jpg";
import nq_30 from "../assets/images/gallery/najah-qatari/nq-30.jpg";
import nq_31 from "../assets/images/gallery/najah-qatari/nq-31.jpg";
import nq_32 from "../assets/images/gallery/najah-qatari/nq-32.jpg";
import nq_33 from "../assets/images/gallery/najah-qatari/nq-33.jpg";
import nq_34 from "../assets/images/gallery/najah-qatari/nq-34.jpg";
import nq_35 from "../assets/images/gallery/najah-qatari/nq-35.jpeg";
import nq_36 from "../assets/images/gallery/najah-qatari/nq-36.jpeg";
import nq_37 from "../assets/images/gallery/najah-qatari/nq-37.jpeg";
import nq_38 from "../assets/images/gallery/najah-qatari/nq-38.jpeg";

import qvab_1 from "../assets/images/gallery/qva/qvab-1.jpg";
import qvab_2 from "../assets/images/gallery/qva/qvab-2.jpg";
import qvab_3 from "../assets/images/gallery/qva/qvab-3.jpg";
import qvab_4 from "../assets/images/gallery/qva/qvab-4.jpg";
import qvab_5 from "../assets/images/gallery/qva/qvab-5.jpg";
import qvab_6 from "../assets/images/gallery/qva/qvab-6.jpg";
import qvab_7 from "../assets/images/gallery/qva/qvab-7.jpg";
import qvab_8 from "../assets/images/gallery/qva/qvab-8.jpg";
import qvab_9 from "../assets/images/gallery/qva/qvab-9.jpg";
import qvab_10 from "../assets/images/gallery/qva/qvab-10.jpg";
import qvab_11 from "../assets/images/gallery/qva/qvab-11.jpg";
import qvab_12 from "../assets/images/gallery/qva/qvab-12.jpg";
import qvab_13 from "../assets/images/gallery/qva/qvab-13.jpg";
import qvab_14 from "../assets/images/gallery/qva/qvab-14.jpg";
import qvab_15 from "../assets/images/gallery/qva/qvab-15.jpg";
import qvab_16 from "../assets/images/gallery/qva/qvab-16.jpg";
import qvab_17 from "../assets/images/gallery/qva/qvab-17.jpg";
import qvab_18 from "../assets/images/gallery/qva/qvab-18.jpg";
import qvab_19 from "../assets/images/gallery/qva/qvab-19.jpg";
import qvab_20 from "../assets/images/gallery/qva/qvab-20.jpg";
import qvab_21 from "../assets/images/gallery/qva/qvab-21.jpg";
import qvab_22 from "../assets/images/gallery/qva/qvab-22.jpg";
import qvab_23 from "../assets/images/gallery/qva/qvab-23.jpg";
import qvab_24 from "../assets/images/gallery/qva/qvab-24.jpg";
import qvab_25 from "../assets/images/gallery/qva/qvab-25.jpg";
import qvab_26 from "../assets/images/gallery/qva/qvab-26.jpg";
import qvab_27 from "../assets/images/gallery/qva/qvab-27.jpg";
import qvab_28 from "../assets/images/gallery/qva/qvab-28.jpg";

import wb_1 from "../assets/images/gallery/with-vibes/wb-1.jpg";
import wb_2 from "../assets/images/gallery/with-vibes/wb-2.jpg";
import wb_3 from "../assets/images/gallery/with-vibes/wb-3.jpg";
import wb_4 from "../assets/images/gallery/with-vibes/wb-4.jpg";
import wb_5 from "../assets/images/gallery/with-vibes/wb-5.jpg";
import wb_6 from "../assets/images/gallery/with-vibes/wb-6.jpg";
import wb_7 from "../assets/images/gallery/with-vibes/wb-7.jpg";
import wb_8 from "../assets/images/gallery/with-vibes/wb-8.jpg";
import wb_9 from "../assets/images/gallery/with-vibes/wb-9.jpg";
import wb_10 from "../assets/images/gallery/with-vibes/wb-10.jpg";

import al_jazera_forum from "../assets/images/al-jazera-forum.jpg";
import al_shaqab_2023 from "../assets/images/al-shaqab-2023.jpg";
import arab_sporttech_forum from "../assets/images/arab-sporttech-forum.jpg";
import najah_qatari from "../assets/images/NjahQatari.png";
import hamad_book_edition from "../assets/images/hamad-book-edition.png";

export const galleryData = [
  {
    id: 1,
    title: "al_jazera_forum",
    image: al_jazera_forum,
    btn_title: "view_all",
    images: [aljf_1, aljf_2, aljf_3, aljf_4, aljf_5],
  },
  {
    id: 2,
    title: "al_shaqab_2023",
    image: al_shaqab_2023,
    btn_title: "view_all",
    images: [
      als2023_1,
      als2023_2,
      als2023_3,
      als2023_4,
      als2023_5,
      als2023_6,
      als2023_7,
      als2023_8,
      als2023_9,
      als2023_10,
      als2023_11,
      als2023_12,
      als2023_13,
      als2023_14,
      als2023_15,
      als2023_16,
      als2023_17,
      als2023_18,
      als2023_19,
    ],
  },
  {
    id: 3,
    title: "arab_sporttech_forum",
    image: arab_sporttech_forum,
    btn_title: "view_all",
    images: [arsf_1, arsf_2, arsf_3, arsf_4, arsf_5, arsf_6, arsf_7, arsf_8],
  },
  {
    id: 4,
    title: "ekbes",
    image: ekb_1,
    btn_title: "view_all",
    images: [
      // ekb_1,
      ekb_2,
      ekb_3,
      ekb_4,
      ekb_5,
      ekb_6,
      ekb_7,
      ekb_8,
      ekb_9,
      // ekb_10,
      ekb_11,
      ekb_12,
      // ekb_13,
      // ekb_14,
      // ekb_15,
      // ekb_16,
      // ekb_17,
      ekb_18,
      ekb_19,
      ekb_20,
      ekb_21,
      ekb_22,
      // ekb_23,
      // ekb_24,
      ekb_25,
      // ekb_26,
      ekb_27,
      // ekb_28,
      ekb_29,
      // ekb_30,
      // ekb_31,
      // ekb_32,
      ekb_33,
      // ekb_34,
      // ekb_35,
      ekb_36,
      // ekb_37,
      ekb_38,
      ekb_39,
      ekb_40,
      ekb_41,
      ekb_42,
      ekb_43,
      ekb_44,
      ekb_45,
      ekb_46,
      ekb_47,
    ],
  },
  {
    id: 5,
    title: "google_clound_event",
    image: gce_1,
    btn_title: "view_all",
    images: [gce_1, gce_2, gce_3, gce_4, gce_5],
  },
  {
    id: 6,
    title: "hamad_book_launch",
    image: hamad_book_edition,
    btn_title: "view_all",
    images: [
      hbl_1,
      hbl_2,
      hbl_3,
      hbl_4,
      hbl_5,
      hbl_6,
      hbl_7,
      hbl_8,
      hbl_9,
      hbl_10,
      hbl_11,
      hbl_12,
    ],
  },
  {
    id: 7,
    title: "la_pelota",
    image: lap_1,
    btn_title: "view_all",
    images: [
      lap_1,
      lap_2,
      lap_3,
      lap_4,
      // lap_5,
      lap_6,
      lap_7,
      lap_8,
      lap_9,
      lap_10,
      lap_11,
      lap_12,
    ],
  },
  {
    id: 8,
    title: "njah_qatari",
    image: najah_qatari,
    btn_title: "view_all",
    images: [
      nq_1,
      nq_2,
      nq_3,
      nq_4,
      nq_5,
      nq_6,
      nq_7,
      nq_8,
      nq_9,
      nq_10,
      nq_11,
      nq_12,
      nq_13,
      nq_14,
      nq_15,
      nq_16,
      nq_17,
      nq_18,
      nq_19,
      nq_20,
      nq_21,
      nq_22,
      nq_23,
      nq_24,
      nq_25,
      nq_26,
      nq_27,
      nq_28,
      nq_29,
      nq_30,
      nq_31,
      nq_32,
      nq_33,
      nq_34,
      nq_35,
      nq_36,
      nq_37,
      nq_38,
    ],
  },
  {
    id: 9,
    title: "qva_basketball",
    image: qvab_1,
    btn_title: "view_all",
    images: [
      qvab_1,
      qvab_2,
      qvab_3,
      qvab_4,
      qvab_5,
      qvab_6,
      qvab_7,
      qvab_8,
      qvab_9,
      qvab_10,
      qvab_11,
      qvab_12,
      qvab_13,
      qvab_14,
      qvab_15,
      qvab_16,
      qvab_17,
      qvab_18,
      qvab_19,
      qvab_20,
      qvab_21,
      qvab_22,
      qvab_23,
      qvab_24,
      qvab_25,
      qvab_26,
      qvab_27,
      qvab_28,
    ],
  },
  {
    id: 10,
    title: "with_vibes",
    image: wb_1,
    btn_title: "view_all",
    images: [wb_1, wb_2, wb_3, wb_4, wb_5, wb_6, wb_7, wb_8, wb_9, wb_10],
  },
];
