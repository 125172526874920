import { useTranslation } from "react-i18next";

const SliderItems = ({
  title = "",
  image = "",
  campaign = "",
  btn_title = "",
  onClick = () => {},
  id,
  video,
}) => {
  const { t } = useTranslation();
  return (
    <div className="item">
      <img src={image} className="img-fluid" alt="" loading="lazy" />
      <div className="item-overlay">
        <div className="item-details">
          <h5>{t(title)}</h5>
          <button
            onClick={() => {
              onClick(video);
            }}
            type="button"
            className="btn primary-btn "
          >
            {t(btn_title)}
          </button>
        </div>
      </div>
    </div>
  );
};
export default SliderItems;
