import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n/i18n.js";
import Router from "./Route";
import { LanguageProvider } from "./context/LanguageContext";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LanguageProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </LanguageProvider>
  </React.StrictMode>
);
