import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.min.js";
import "aos/dist/aos.css";
import "./assets/scss/main.scss";
import "./assets/custom.css";
import { Loader } from "./components";

const Home = lazy(() => import("./pages/Home"));
const Contact = lazy(() => import("./pages/Contact"));
const Gallery = lazy(() => import("./pages/Gallery"));
const Services = lazy(() => import("./pages/Services"));
const NotFound = lazy(() => import("./components/NotFound"));

const RouterComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery/:id" element={<Gallery />} />
        <Route path="/services/:id" element={<Services />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default RouterComponent;
