import React from "react";
import scrollIcon from "../assets/images/Scroll.gif";
import scrolltext from "../assets/images/scroll-text.png";
import scrolltextAr from "../assets/images/arabic-scroll-text.png";
import { useLanguage } from "../context/LanguageContext";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  const { lang } = useLanguage();

  const handleMenuClick = (e) => {
    e.preventDefault();
    const element = document.getElementById("Brand");
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };
  return (
    <section className="banner">
      <div className="hero-text col-12 col-sm-9">
        <div className="container-lg">
          <h1 data-aos-easing="ease-in-out-back" data-aos-duration="1000">
            {t("banner_text")}
          </h1>
        </div>
      </div>
      <div className="video-holder">
        <video id="banner_video" autoPlay muted loop playsInline preload="metadata">
          <source src={"/banner.mp4"} type="video/mp4"></source>
        </video>
      </div>
      <button style={{backgroundColor:"transparent",border:"none"}}
        onClick={handleMenuClick}
        className="d-flex flex-column align-items-center scrollDown"
        aria-label="scroll down to explore more"
      >
        <img
          src={lang == "ar" ? scrolltextAr : scrolltext}
          className="img-fluid"
          alt=""
        />
        <img src={scrollIcon} className="img-fluid" alt="" />
      </button>
    </section>
  );
};
export default Banner;
