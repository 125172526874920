import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleMenuClick = (e, section) => {
    e.preventDefault();
    const element = document.getElementById(section);
    const { pathname } = window.location;
    if (pathname === "/contact") {
      navigate(`/#${section}`);
    } else {
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  };
  return (
    <footer className="footer">
      <div className="container-lg">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-4">
            <a href="/" className="footer-logo" aria-label="go to home">
              <img src={logo} alt="" loading="lazy"></img>
            </a>
            <p>
              © {t("loom")} 2022. {t("all_rights_reserved")}
            </p>
          </div>
          <div className="col-md-3 col-xl-2 mb-3">
            <h5 className="footer-link">{t("quick_links")}</h5>
            <div className="d-flex justify-content-start justify-content-lg-between">
              <ul className="footer-menu">
                <li>
                  <a onClick={(e) => handleMenuClick(e, "Brand")} href="#Brand">
                    {t("about_us")}
                  </a>
                </li>
                <li>
                  <a onClick={(e) => handleMenuClick(e, "WeDo")} href="#WeDo">
                    {t("services")}
                  </a>
                </li>
              </ul>
              <ul className="footer-menu ps-4">
                <li>
                  <a
                    onClick={(e) => handleMenuClick(e, "OurWork")}
                    href="#OurWork"
                  >
                    {t("our_work")}
                  </a>
                </li>
                {/* <li>
                  <a onClick={(e) => handleMenuClick(e, 'Office')}  href="#Office">{t("our_office")}</a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <h5 className="footer-link">{t("follow_us")}</h5>
            <nav className="footer-social">
              <a
                href="https://www.facebook.com/Loomqa-109818098457392"
                target={"_blank"}
                aria-label="follow us on facebook"
              >
                <i className="fab fa-facebook"></i>
              </a>
              <a
                aria-label="follow us on linkedin"
                href="https://www.linkedin.com/company/loom-qa/about/"
                target={"_blank"}
              >
                <i className="fab fa-linkedin"></i>
              </a>
              <a
                href="https://www.instagram.com/loom.qa/"
                target={"_blank"}
                aria-label="follow us on instagram"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://twitter.com/LoomQa"
                target={"_blank"}
                aria-label="follow us on twitter"
              >
                <i className="fab fa-twitter"></i>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
