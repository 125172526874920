import React, { useEffect } from "react";
import Modal from "react-modal";

const ReactModal = ({
  isOpen = false,
  onAfterOpen = () => { },
  closeModal = () => { },
  children = <></>,
  contentLabel = "Example Modal",
  classname = "video-modal common-modal"
}) => {
  useEffect(() => {
    Modal.setAppElement("body");
  }, [])
  
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onRequestClose={closeModal}
        // style={customStyles}
        contentLabel={contentLabel}
        className={classname}
      >
        <div>
          <button
            type="button"
            className="btn-close"
            onClick={closeModal}
            // data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        {children}
      </Modal>
    </div>
  );
};
export default ReactModal