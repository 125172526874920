import wrk_1 from "../assets/images/our-work/wrk-1.svg";
import wrk_2 from "../assets/images/our-work/wrk-2.svg";
import wrk_3 from "../assets/images/our-work/wrk-3.svg";
import wrk_4 from "../assets/images/our-work/wrk-4.svg";
import wrk_5 from "../assets/images/our-work/wrk-5.svg";
import wrk_6 from "../assets/images/our-work/wrk-6.svg";
import wrk_7 from "../assets/images/our-work/wrk-7.svg";
import wrk_8 from "../assets/images/our-work/wrk-8.svg";
import wrk_9 from "../assets/images/our-work/wrk-9.svg";
import wrk_10 from "../assets/images/our-work/wrk-10.svg";
import wrk_11 from "../assets/images/our-work/wrk-11.svg";
import wrk_12 from "../assets/images/our-work/wrk-12.svg";
import wrk_13 from "../assets/images/our-work/wrk-13.svg";
import wrk_14 from "../assets/images/our-work/wrk-14.svg";
import wrk_15 from "../assets/images/our-work/wrk-15.svg";
import wrk_16 from "../assets/images/our-work/wrk-16.svg";
import wrk_18 from "../assets/images/our-work/wrk-18.svg";
import wrk_19 from "../assets/images/our-work/wrk-19.svg";
import wrk_20 from "../assets/images/our-work/wrk-20.svg";
import wrk_21 from "../assets/images/our-work/wrk-21.png";
import wrk_22 from "../assets/images/our-work/wrk-22.png";
import wrk_23 from "../assets/images/our-work/wrk-23.png";
import wrk_24 from "../assets/images/our-work/wrk-24.png";
import wrk_25 from "../assets/images/our-work/wrk-25.png";
import wrk_26 from "../assets/images/our-work/wrk-26.png";
import wrk_27 from "../assets/images/our-work/wrk-27.svg";
import wrk_28 from "../assets/images/our-work/wrk-28.png";
import wrk_29 from "../assets/images/our-work/wrk-29.png";
import wrk_30 from "../assets/images/our-work/wrk-30.png";
import wrk_31 from "../assets/images/our-work/wrk-31.png";
import wrk_32 from "../assets/images/our-work/wrk-32.png";
import wrk_33 from "../assets/images/our-work/wrk-33.png";
import wrk_34 from "../assets/images/our-work/wrk-34.png";
import wrk_35 from "../assets/images/our-work/wadha.png";
import wrk_36 from "../assets/images/our-work/vicuna.png";
import wrk_37 from "../assets/images/our-work/ministry-1.jpg";
import wrk_38 from "../assets/images/our-work/dynastea.png";
import wrk_39 from "../assets/images/our-work/qsfa.png";
import wrk_40 from "../assets/images/our-work/sliver.png";
import wrk_41 from "../assets/images/our-work/yara.png";

export const clientData = [
  wrk_1,
  wrk_2,
  wrk_37,
  wrk_3,
  wrk_4,
  wrk_5,
  wrk_6,
  wrk_7,
  wrk_8,
  wrk_9,
  wrk_10,
  wrk_11,
  wrk_12,
  wrk_13,
  wrk_14,
  wrk_15,
  wrk_16,
  wrk_18,
  wrk_19,
  wrk_20,
  wrk_21,
  wrk_22,
  wrk_23,
  wrk_24,
  wrk_25,
  wrk_26,
  wrk_27,
  wrk_28,
  wrk_29,
  wrk_30,
  wrk_31,
  wrk_32,
  wrk_33,
  wrk_34,
  wrk_36,
  wrk_35,
  wrk_38,
  wrk_39,
  wrk_40,
  wrk_41,
];
