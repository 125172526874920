import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/logo.svg";
import { useLanguage } from "../context/LanguageContext";

const Header = ({ headerClass }) => {
  const { lang, toggleLanguage } = useLanguage(); 
  const { t } = useTranslation(); 
  const navigate = useNavigate();

  const onClickMenu = () => {
    const body = document.body;
    body.className = body.className === "" ? "menu-open" : "";
  };

  const handleMenuClick = (e, section) => {
    e.preventDefault();
    const element = document.getElementById(section);
    const { pathname } = window.location;

    if (pathname === "/contact") {
      navigate(`/#${section}`);
    } else {
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  };

  return (
    <header className={`${headerClass}`}>
      <div className="container-lg">
        <div className="row gx-0">
          <div className="col">
            <Link to="/" className="header-logo" aria-label="goto to home">
              <img src={logo} alt="Logo" />
            </Link>
          </div>

          <div className="col-auto d-flex align-items-center">
            <nav className="header-menu">
              <ul role="menu">
                <li role="menuitem" className="active">
                  <a onClick={(e) => handleMenuClick(e, "Brand")} href="#Brand">
                    {t("who_we_are")}
                  </a>
                </li>
                <li role="menuitem">
                  <a onClick={(e) => handleMenuClick(e, "WeDo")} href="#WeDo">
                    {t("what_we_do")}
                  </a>
                </li>
                <li role="menuitem">
                  <a
                    onClick={(e) => handleMenuClick(e, "OurWork")}
                    href="#OurWork"
                  >
                    {t("our_work")}
                  </a>
                </li>
                <li role="menuitem">
                  <a
                    onClick={(e) => handleMenuClick(e, "Office")}
                    href="#Office"
                  >
                    {t("get_in_touch")}
                  </a>
                </li>
              </ul>
            </nav>

            <button
              id="hamburgermenu"
              className="btn hamburger-btn"
              onClick={onClickMenu}
              aria-label="menu"
            >
              <span className="hamburger">
                <span className="bar1"></span>
                <span className="bar2"></span>
                <span className="bar3"></span>
              </span>
            </button>
            <button className="lang" onClick={toggleLanguage}>
              <span>{lang === "en" ? "العربية" : "English"}</span>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
