import React from "react";
import not_found_icon from "../assets/images/robot-space.png";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="not-found-holder">
      <div className="">
        <h3>404</h3>
        <h5>{t("oops")}</h5>
        <p>{t("page_not_found")}</p>
      </div>
      <img
        alt=""
        src={not_found_icon}
        className="img-fluid spin"
        loading="lazy"
      />
    </div>
  );
};

export default NotFound;
