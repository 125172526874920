import React from "react";
import astronautOnMobile from "../assets/images/astranaut-on-mobile.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { serviceData } from "../data/serviceData";

const WeDo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div id="WeDo">
      <section className="sec-padding bg-grey-color overflow-hidden mb-5">
        <div className="container-lg">
          <div className="row">
            <div className="col-lg-12">
              <div className="sec-content">
                <h2 className="mb-3">{t("what_we_do")}</h2>
                <h3 className="mb-1 fw-bold">{t("Services")}</h3>
                <h3 className="mb-3">{t("we_do_description")}</h3>
              </div>
            </div>
            <div className="col-lg-12 box">
              <div className="services-wrapper pt-3 pt-lg-5">
                <div className="row g-2 gy-3">
                  {serviceData?.map((item) => {
                    return (
                      <div
                        key={item?.href}
                        className="col-12 col-sm-6 col-lg-3"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(`/services${item?.href}`)}
                      >
                        <div className="service-wrap">
                          <div className="service-holder">
                            <img
                              src={item.icon}
                              className={item.className}
                              alt=""
                              loading="lazy"
                              height={"110px"}
                              width="70px"
                            />
                            <h4 className="mb-2">{t(`${item.title}`)}</h4>
                            <p>{t(`${item.description}`)}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="mobile-float-img">
        <img
          src={astronautOnMobile}
          className="img-fluid"
          alt=""
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default WeDo;
