import React from "react";
import { useTranslation } from "react-i18next";
import { clientData } from "../data/clientData";

const OurWork = () => {
  const { t } = useTranslation();

  return (
    <div id="OurClient">
      <section className="sec-padding">
        <div className="container-lg">
          <div className="row">
            <div className="col-lg-12">
              <h2
                className="mb-3" /* data-aos="fade-up" data-aos-duration="1000" */
              >
                {t("our_client")}
              </h2>
              <h3 className="mb-3">{t("our_client_desc")}</h3>
              <div className="works-wrapper" >
                <div className="row justify-content-center" style={{ margin: "0 auto" }}>
                  {clientData?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="col-auto"
                        style={{ padding: "1.5rem" }}
                      >
                        <figure
                          className="work-icon"
                          style={{ objectFit: "contain" }}
                        >
                          <img
                            src={item}
                            alt=""
                            loading="lazy"
                            style={{ overflow: "hidden" }}
                          />
                        </figure>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurWork;
