import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { galleryData } from "../data/galleryData";

export default function GallerySlider() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClick = (id) => {
    navigate(`/gallery/${id}`);
  };

  const settings = {
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          autoplaySpeed: 3000,
          infinite: true,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          infinite: true,
          arrows: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <section className="slider-outer sec-padding case-slider mb-2 mb-md-5">
      <div className="container-lg">
        <div className="row">
          <Slider {...settings} className="work-slider">
            {galleryData &&
              galleryData.map((item, index) => {
                return <SliderItems key={index} onClick={onClick} {...item} />;
              })}
          </Slider>
        </div>
      </div>
    </section>
  );
}



const SliderItems = ({
  title = "",
  image = "",
  btn_title = "",
  onClick = () => {},
  id = "",
}) => {
  const { t } = useTranslation();
  return (
    <div className="item">
      <img src={image} className="img-fluid" loading="lazy" alt="" />
      <div className="item-overlay">
        <div className="item-details">
          <h5>{t(title)}</h5>
          <button
            onClick={() => onClick(id)}
            type="button"
            className="btn primary-btn "
          >
            {t(btn_title)}
          </button>
        </div>
      </div>
    </div>
  );
};
